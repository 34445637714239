import { render, staticRenderFns } from "./CmpViewAbout.vue?vue&type=template&id=7a907ac6"
var script = {}
import style0 from "./CmpViewAbout.vue?vue&type=style&index=0&id=7a907ac6&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports